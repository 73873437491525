/**
 * Created by Yun.kou on 17/08/17.
 */

export const ID = '@third-party-manage'

export default {
  ID: ID,
  GET_LEDGER_MAX_Count: `${ID}/GET_LEDGER_MAX_Count`,
  GET_PLAN_MAX_Count: `${ID}/GET_PLAN_MAX_Count`,
  SYNC_ORDERS: `${ID}/SYNC_ORDERS`,
  SET_SMS: `${ID}/SET_SMS`,
  GET_CRM_BIND_INFO: `${ID}/GET_CRM_BIND_INFO`,
  SYNC_CRM_INFO: `${ID}/SYNC_CRM_INFO`,
  SWITCH_THIRD_PARTY: `${ID}/SWITCH_THIRD_PARTY`,
  GET_DISPLAY_FIELDS: `${ID}/GET_DISPLAY_FIELDS`,
  REMIND_REIMBURSE: `${ID}/REMIND_REIMBURSE`,
  GET_MAPPING_RELATION: `${ID}/GET_MAPPING_RELATION`,
  GET_ALL_ORDER_IDS: `${ID}/GET_ALL_ORDER_IDS`,
  SAVE_PLATFORM: `${ID}/SAVE_PLATFORM`,
  GET_ALL_PLATFORMS: `${ID}/GET_ALL_PLATFORMS`,
  GET_ALL_PLATFORMS_SEARCH: `${ID}/GET_ALL_PLATFORMS_SEARCH`,
  CREATE_ENTITY: `${ID}/CREATE_ENTITY`,
  VALIDATE_FORMULA_GRAMMAR: `${ID}/VALIDATE_FORMULA_GRAMMAR`,
  SAVE_ENTITY: `${ID}/SAVE_ENTITY`,
  GET_ENTITY_INFO: `${ID}/GET_ENTITY_INFO`,
  GET_ENTITY_LIST: `${ID}/GET_ENTITY_LIST`,
  GET_ALL_ENTITY_LIST: `${ID}/GET_ALL_ENTITY_LIST`,
  GET_PLATFORM_INFO: `${ID}/GET_PLATFORM_INFO`,
  SAVE_RULE: `${ID}/SAVE_RULE`,
  GET_MY_THIRDPARTY_LIST: `${ID}/GET_MY_THIRDPARTY_LIST`,
  GET_SUB_KEYS: `${ID}/GET_SUB_KEYS`,
  MODIFY_DATALINK_COUNT: `${ID}/MODIFY_DATALINK_COUNT`,
  DISABLE_DATALINK: `${ID}/DISABLE_DATALINK`,
  SETTOP_DATALINK: `${ID}/SETTOP_DATALINK`,
  GET_DATALINK: `${ID}/GET_DATALINK`,
  GET_PLATFORM_DATALINK: `${ID}/GET_PLATFORM_DATALINK`,
  GET_PRIVATECAR_PLATFORM: `${ID}/GET_PRIVATECAR_PLATFORM`,
  CHANGE_FZR_OR_CYR_LIST: `${ID}/CHANGE_FZR_OR_CYR_LIST`,
  SAVE_DETALINK_PLANED: `${ID}/SAVE_DETALINK_PLANED`,
  DATALINK_PLAN_DELETE: `${ID}/DATALINK_PLAN_DELETE`,
  GET_MONEY_FIELD_BYENTITYID: `${ID}/GET_MONEY_FIELD_BYENTITYID`,
  GET_BIND_ENTITY_FIELDS: `${ID}/GET_BIND_ENTITY_FIELDS`,
  GET_MONEY_ABILITY_FIELDS: `${ID}/GET_MONEY_ABILITY_FIELDS`,
  UPDATE_LEDGER_CONGIF: `${ID}/UPDATE_LEDGER_CONGIF`,
  DELETE_LEDGER_CONGIF: `${ID}/DELETE_LEDGER_CONGIF`,
  GET_VISIBLE_LIST: `${ID}/GET_VISIBLE_LIST`,
  POST_VISIBLE_LIST: `${ID}/POST_VISIBLE_LIST`,
  GET_LEDGER_LIST: `${ID}/GET_LEDGER_LIST`,
  GET_PLAN_LIST: `${ID}/GET_PLAN_LIST`,
  RECOMPUTE_LEDGET_CONFIG: `${ID}/RECOMPUTE_LEDGET_CONFIG`,
  GET_TEMPCONFIG_CANDIDATE: `${ID}/GET_TEMPCONFIG_CANDIDATE`,
  GET_DATALINK_TEMPLATE: `${ID}/GET_DATALINK_TEMPLATE`,
  SAVE_DATALINK_TEMPLATE: `${ID}/SAVE_DATALINK_TEMPLATE`,
  GET_ENTITY_DATA: `${ID}/GET_ENTITY_DATA`,
  GET_ENTITY_DETAIL_DATA: `${ID}/GET_ENTITY_DETAIL_DATA`,
  GET_SEARCH_DATA_LINK: `${ID}/GET_SEARCH_DATA_LINK`,
  SEARCH_DATALINK: `${ID}/SEARCH_DATALINK`,
  GET_ENTITY_BY_PLATFORM_NAME: `${ID}/GET_ENTITY_BY_PLATFORM_NAME`,
  SEARCH_PLATFORM_DATALINK: `${ID}/SEARCH_PLATFORM_DATALINK`,
  GET_COLUMN_VISIBILITY: `${ID}/GET_COLUMN_VISIBILITY`,
  SET_COLUMN_VISIBILITY: `${ID}/SET_COLUMN_VISIBILITY`,
  CREATE_ENTITY_CHILD: `${ID}/CREATE_ENTITY_CHILD`,
  SAVE_SPECIFICATIONT_TYPE: `${ID}/SAVE_SPECIFICATIONT_TYPE`,
  EDIT_SPECIFICATIONT_TYPE: `${ID}/EDIT_SPECIFICATIONT_TYPE`,
  DELETE_SPECIFICATIONT_TYPE: `${ID}/DELETE_SPECIFICATIONT_TYPE`,
  DATALINK_TO_ELEME: `${ID}/DATALINK_TO_ELEME`,
  GET_DATALINK_LOG_BY_ENTUTYID: `${ID}/GET_DATALINK_LOG_BY_ENTUTYID`,
  GET_DATALINK_ENTITY_FIELD_INFO_BY_ID: `${ID}/GET_DATALINK_ENTITY_FIELD_INFO_BY_ID`,
  GET_DATALINK_LOG_BY_DATALINKID: `${ID}/GET_DATALINK_LOG_BY_DATALINKID`,
  GET_DATALINK_DIMENSION: `${ID}/GET_DATALINK_DIMENSION`,
  GET_DATALINK_ENUMERATE: `${ID}/GET_DATALINK_ENUMERATE`,
  GET_ENTITY_FORM_INFO: `${ID}/GET_ENTITY_FORM_INFO`,
  GET_TRAVEL_MANAGE_LOG_BY_DATALINKID: `${ID}/GET_TRAVEL_MANAGE_LOG_BY_DATALINKID`,
  GET_TRAVEL_MANAGE_LOG: `${ID}/GET_TRAVEL_MANAGE_LOG`,
  SYNC_TARVEL_DATA: `${ID}/SYNC_TARVEL_DATA`,
  SAVE_VISIBILITY: `${ID}/SAVE_VISIBILITY`,
  SAVE_FIELDS: `${ID}/SAVE_FIELDS`,
  SEARCH_ORDER_MATCH_ORDER:`${ID}/SEARCH_ORDER_MATCH_ORDER`,
  GET_REVEIPT_DATALINK_FIELD: `${ID}/GET_REVEIPT_DATALINK_FIELD`,
}
