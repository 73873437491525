/**
 * Created by Yun.kou on 17/08/17.
 */
// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import keys from './key'

import {
  getLedgerMaxCount,
  getPlannedMaxCount,
  changeFZRorCYRList,
  getAllPlatform,
  getPlatformSearch,
  getDataLink,
  getEntityData,
  getEntityDetailData,
  getEntityInfo,
  getEntityList,
  getImportStatus,
  getLedgerList,
  getMoneyFieldByEntityId,
  getMyThirdPartyList,
  getPlanList,
  getPlatformInfo,
  getSearchDataLink,
  getColumnVisibility,
  saveEntity,
  searchDatalink,
  getEntityByPlatformName,
  searchPlatfromDatalink,
  handleExportAll,
  handleLayoutExport,
  handleSelectExport,
  handleExportAllMine,
  handleLayoutExportMine,
  handleExportPDF,
  saveSpecificationType,
  editSpecificationType,
  deleteSpecificationType,
  getAllEntityList,
  savePlatform,
  getDataLinkLogByDataLinkId,
  getEntityFieldInfoById,
  getTempConfigCandidate,
  getDataLinkTemplate,
  getEntityDimension,
  getDataLinkFields,
  syncTravelData,
  getLogs,
  getLogsByDataLinkId,
  getLocal,
  getOrderReportUnifyInvoiceDataListByCorpId,
  getOrderReportUnifyInvoiceDataListByBatchId,
  getTravelManagePlatform,
  updateDatalinkActive,
  searchOrderMatchOrder,
  getReceiptDataLinkField,
  addOccupyConfig,
  editOccupyConfig,
  getOccupyConfigDetail,
  getOccupyConfigList,
  delOccupyConfig,
} from './third-party-manage-action'

import loadable from '@loadable/component'

export default [
  {
    id: '@third-party-manage',
    path: '/third-party-manage',
    ref: '/',
    onload: () => import('./third-party-manage-view'),
    dependencies: ['@auth-check'],
    reducer: () => import('./third-party-manage-reducer'),

    'get:all:platforms'() {
      return api.dispatch(getAllPlatform())
    },
    'get:all:platforms:serach'(data) {
      return api.dispatch(getPlatformSearch(data))
    },
    'get:receipt:dataLink:field'(data) {
      return api.dispatch(getReceiptDataLinkField(data))
    },

    'get:platform:info'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(getPlatformInfo(params))
          .then((res) => {
            resolve(res.value)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:entity:info'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(getEntityInfo(params))
          .then((res) => {
            resolve(res.value)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:entity:list'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(getEntityList(params))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:entity:all:list'(params) {
      return api.dispatch(getAllEntityList(params))
    },

    'save:platform'(params) {
      return api.dispatch(savePlatform(params))
    },

    'save:entity'(params) {
      return api.dispatch(saveEntity(params))
    },

    'get:entity:field:info:byId'(id) {
      return api.dispatch(getEntityFieldInfoById(id))
    },

    'get:my:thirdparty:list'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(getMyThirdPartyList(params))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:dataLink:list'(param, query) {
      return api.dispatch(getDataLink(param, query))
    },

    'get:planList:list'(params) {
      return api.dispatch(getPlanList(params))
    },

    'get:ledgerList:list'(params) {
      return api.dispatch(getLedgerList(params))
    },

    'get:dataLink:import:status'(param, query) {
      return getImportStatus(param, query)
    },

    'put:dataLink:FZRorCYRList'(param) {
      return api.dispatch(changeFZRorCYRList(param))
    },
    'get:ledger:max:count'(param) {
      return api.dispatch(getLedgerMaxCount(param))
    },
    'get:planned:max:count'(param) {
      return api.dispatch(getPlannedMaxCount(param))
    },
    'get:entity:plan:config:fields'(param) {
      return api.dispatch(getMoneyFieldByEntityId(param))
    },
    'get:entity:data'(params) {
      return api.dispatch(getEntityData(params))
    },
    'get:entity:detail:data'(params) {
      return api.dispatch(getEntityDetailData(params))
    },
    'get:searchDataLink:data'(params) {
      return api.dispatch(getSearchDataLink(params))
    },
    'get:getColumnVisibility:data'(params) {
      return api.dispatch(getColumnVisibility(params))
    },
    'search:datalink:by:entityId'(param, query) {
      return api.dispatch(searchDatalink(param, query))
    },
    'get:entity:by:platform:name'(name, filter) {
      return api.dispatch(getEntityByPlatformName(name, filter))
    },
    'search:platform:datalink'(param, query) {
      return api.dispatch(searchPlatfromDatalink(param, query))
    },
    'search:order:match:order'(param, query) {
      return api.dispatch(searchOrderMatchOrder(param, query))
    },
    'export:dataLink'(id, query) {
      return handleExportAll(id, query)
    },
    'export:dataLink:layout'(id, query) {
      return handleLayoutExport(id, query)
    },
    'export:dataLink:select'(id, query, ids) {
      return handleSelectExport(id, query, ids)
    },
    'export:dataLink:mine'(id, query) {
      return handleExportAllMine(id, query)
    },
    'export:dataLink:layout:mine'(id, query) {
      return handleLayoutExportMine(id, query)
    },
    'export:dataLink:pdf'(id, query) {
      return handleExportPDF(id, query)
    },
    'get:travel:manage:logs'(ids) {
      return api.dispatch(getLogs(ids))
    },
    'get:travel:manage:logs:by:datalink:id'(id) {
      return api.dispatch(getLogsByDataLinkId(id))
    },
    'sync:travel:data'(value) {
      return api.dispatch(syncTravelData(value))
    },
    'save:specification:type'(params) {
      return api.dispatch(saveSpecificationType(params))
    },
    'edit:specification:type'(params) {
      return api.dispatch(editSpecificationType(params))
    },
    'delete:specification:type'(params) {
      return api.dispatch(deleteSpecificationType(params))
    },
    'get:temconfig:candidate'(params) {
      return api.dispatch(getTempConfigCandidate(params))
    },
    'get:datalink:template'(params) {
      return api.dispatch(getDataLinkTemplate(params))
    },
    'get:datalink:log:by:datalinkid'(params) {
      return api.dispatch(getDataLinkLogByDataLinkId(params))
    },
    'get:dataLink:Entity:by:entityId'(params) {
      return api.dispatch(getTempConfigCandidate(params))
    },
    'get:dataLink:dimension'(params) {
      return api.dispatch(getEntityDimension(params))
    },
    'get:entity:fields'(params) {
      return getDataLinkFields(params)
    },
    'get:local'() {
      return getLocal
    },
    'get:specificion:contract'(params) {
      return getSpecificationByType(params)
    },
    'create:payment:plan': async (entity) => {
      const { legerDataLinkType } = entity
      const contractId = entity?.dataLink?.id
      const data = legerDataLinkType !== 'AMORTIZE_PLAN' ? await getPaymentPlans({ contractId }) : await getDeductPlan({ contractId })
      const values = await api.open('@third-party-manage:PaymentPlanModal', { entityInfo: { data: entity, planType: legerDataLinkType !== 'AMORTIZE_PLAN' ? 'createPaymentPlan' : 'createArtiPlan' }, value: data?.items })
      legerDataLinkType !== 'AMORTIZE_PLAN' ? await savePaymentPlans(values) : await saveDeductPlan(values)
      const newData = legerDataLinkType !== 'AMORTIZE_PLAN' ? await getPaymentPlans({ contractId }) : await getDeductPlan({ contractId })
      return api.open('@third-party-manage:PaymentPlanPreviewModal', { data: newData?.items, type: legerDataLinkType !== 'AMORTIZE_PLAN' ? 'pay' : 'deduct' })
    },
    'get:contract:entity:byType': (entityId) => {
      return getContractEntityType(entityId)
    },
    'getLedger': (ledgerId) => {
      return getLedgerType(ledgerId)
    },
    /*通过开票方Id获取订单列表*/
    'get:OrderReportUnifyInvoiceDataList:byCorpId': (params) => {
      return getOrderReportUnifyInvoiceDataListByCorpId(params)
    },
    /*通过开票批次Id获取订单列表*/
    'get:OrderReportUnifyInvoiceDataList:byBatchId': (params) => {
      return getOrderReportUnifyInvoiceDataListByBatchId(params)
    },
    'get:travelManage:platformId': () => {
      return getTravelManagePlatform()
    },
    'update:platform:datalink:active'(param) {
      return updateDatalinkActive(param)
    },
    // 订单金额占用配置新增
    'add:occupy:config'(param) {
      return addOccupyConfig(param)
    },
    // 订单金额占用配置编辑
    'edit:occupy:config'(param) {
      return editOccupyConfig(param)
    },
    // 订单金额占用配置详情
    'get:occupy:config:detail'(param) {
      return getOccupyConfigDetail(param)
    },
    // 订单金额占用配置列表
    'get:occupy:config:list'(id) {
      return getOccupyConfigList(id)
    },
    // 订单金额占用配置删除
    'delete:occupy:config'(id) {
      return delOccupyConfig(id)
    },
  },
  {
    path: '/third-party-item/:platformId',
    ref: '/',
    onload: () => import('./third-party-item/third-party-item-content')
  },
  {
    path: '/travel-manage',
    ref: '/',
    onload: () => import('./travel-manage-split/travel-manage-split-wrapper')
  },
  {
    path: '/order-manage',
    ref: '/',
    onload: () => import('./travel-manage-split/travel-manage-split-wrapper')
  },
  {
    point: '@@components',
    namespace: keys.ID,
    onload: () => [{ key: 'OrderReport', component: () => import('./order-report/OrderReport') }]
  },
  {
    point: '@@components',
    namespace: keys.ID,
    onload: () => [{ key: 'ReceiptBillSetting', component: () => import('./receipt-setting') }]
  },
  {
    point: '@@menus',
    onload: () => {
      return [
        {
          id: 'third-party-manage',
          pId: 'enterprise-manage',
          permissions: ['SYS_ADMIN'],
          powers: ['DataLinkManage'],
          weight: 11,
          label: '自定义扩展',
          href: '/third-party-manage',
          icon: 'third-party-manage'
        }
      ]
    }
  },
  {
    point: '@@layers',
    prefix: '@third-party-manage',
    onload: () => import('./layers')
  },

  {
    resource: '@third-party-manage',
    value: {
      ['data-interconnection/data-entity-modal/OperationTable']: loadable(
        () => import('./data-interconnection/data-entity-modal/OperationTable')
      ),
      ['data-interconnection/config-rules-modal/DependencesComponent']: loadable(
        () => import('./data-interconnection/config-rules-modal/DependencesComponent')
      ),
      ['data-interconnection/data-entity-modal/FieldConfig/FieldConfig']: loadable(
        () => import('./data-interconnection/data-entity-modal/FieldConfig/FieldConfig')
      ),
      ['third-party-item/third-party-item-content']: loadable(() => import('./third-party-item/third-party-item-content'))
    }
  }
]
