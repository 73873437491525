/**
 * Created by Yun.kou on 17/08/17.
 */
import { app as api } from '@ekuaibao/whispered'
import { Fetch, Resource } from '@ekuaibao/fetch'
import { QuerySelect } from 'ekbc-query-builder'
import key from './key'

const dataLinkV1 = new Resource('/api/v1/datalink')
const dataLink = new Resource('/api/v2/datalink')
const dataLinkHomeVisible = new Resource('/api/v1/organization')
const rules = new Resource('/api/v1/mapping')
const ledgerAction = new Resource('/api/v2/datalink/ledger')
const propertySetAction = new Resource('/api/flow/v2/propertySet')
const specification = new Resource('/api/form/v2')
const dataLinkElem = new Resource('/api/tpp/v2/elem/dataLink/dataLinkToElem')
const tpp = new Resource('/api/tpp/v2')
const dimensions = new Resource('/api/v1/basedata/dimensionItems')
const departments = new Resource('/api/v1/organization/departments')
const supplierV3 = new Resource('/api/supplier/v3')
const specificationV3 = new Resource('/api/form/v2/specificationGroups')
const propertySet = new Resource('/api/flow/v2/propertySet')
const orderCenter = new Resource('/api/tpp/v2/order/center')
const tppOrder = new Resource('/api/tpp/v2/order/center')
const enums = new Resource("/api/v1/basedata/enumItems/byEnumCode")
const dimension = new Resource('/api/flow/v2/referables')
const syncTravelOrder = new Resource('/api/tpp/v2/travelManagement/order')
const occupyConfig = new Resource('/api/v1/requisition/occupyConfig')
// const getTravelManageFlatform = new Resource("/api/tpp/v2/travelManagement/getPlatFormId")

// 订单金额占用配置新增
export function addOccupyConfig(params) {
  return occupyConfig.POST('', params)
}

// 订单金额占用配置编辑
export function editOccupyConfig(params) {
  return occupyConfig.PUT(`/$id`, params)
}

// 订单金额占用配置详情 
export function getOccupyConfigDetail(params) {
  return occupyConfig.GET(`/$id`, params)
}

// 订单金额占用配置列表
export function getOccupyConfigList(id) {
  return occupyConfig.GET(`/list`, id)
}

// 订单金额占用配置删除
export function delOccupyConfig(id) {
  return occupyConfig.PUT(`/$id/delete`, id)
}

// 同步行程订单
export function syncPlatform(params) {
  return syncTravelOrder.PUT(`/synTravelOrder`, params)
}
// 获取行程管理平台id
export function getTravelManagePlatform() {
  return tpp.GET('/travelManagement/getPlatFormId')
}

export function savePlatform(params) {
  let payload = {}
  if (!!params.id) {
    payload = dataLink.PUT('/platform/$id', params)
  } else {
    payload = dataLink.POST('/platform', params)
  }
  return {
    type: key.SAVE_PLATFORM,
    payload
  }
}

export function getAllPlatform() {
  const params = {
    join: 'icon.fileId,fileId,/v1/attachment/attachments'
  }
  return {
    type: key.GET_ALL_PLATFORMS,
    payload: dataLink.GET('/platform', params)
  }
}
export function getPlatformSearch(data) {
  const params = {
    ...data,
    join: 'icon.fileId,fileId,/v1/attachment/attachments',
    join$1: 'adminIds,admin,/v1/organization/staffs'
  }
  return {
    type: key.GET_ALL_PLATFORMS_SEARCH,
    payload: dataLink.GET('/platform/search', params)
  }
}

export function createEntity(params) {
  return {
    type: key.CREATE_ENTITY,
    payload: dataLink.POST('/entity', params)
  }
}

export function createEntityChild(params) {
  return {
    type: key.CREATE_ENTITY_CHILD,
    payload: dataLink.POST('/entity/child', params)
  }
}
export function validateFormulaGrammar(params) {
  return {
    type: key.VALIDATE_FORMULA_GRAMMAR,
    payload: dataLink.POST('/entity/grammar', params)
  }
}

export function saveEntity(params) {
  return {
    type: key.CREATE_ENTITY,
    payload: dataLink.PUT('/entity', params)
  }
}

export function getEntityInfo(params) {
  return {
    type: key.GET_ENTITY_INFO,
    payload: dataLink.GET('/entity/$id', params)
  }
}

export function getPlatformInfo(params) {
  params.join = 'icon.fileId,fileId,/v1/attachment/attachments'
  params.join$1 = 'adminIds,admin,/v1/organization/staffs'
  return {
    type: key.GET_PLATFORM_INFO,
    payload: dataLink.GET('/platform/$id', params)
  }
}

export function getEntityList(params) {
  return {
    type: key.GET_ENTITY_LIST,
    payload: dataLink.POST('/entity/platformId/$id', params)
  }
}

// 保存经费本配置人员
export function saveVisibility(params) {
  return {
    type: key.SAVE_VISIBILITY,
    payload: dataLink.POST('/entity/exportVisibility/$dataLinkEntityId', params)
  }
}

export function getAllEntityList(params) {
  const join = `platformId,platformId,/v2/datalink/platform`
  //修改获取所有业务对象请求  当有参数时请求旧的，没有时请求新的接口
  if (params && Object.keys(params).length > 0) {
    return {
      type: key.GET_ALL_ENTITY_LIST,
      payload: dataLink.GET('/entity/getEntityList', { ...params, join })
    }
  } else {
    return {
      type: key.GET_ALL_ENTITY_LIST,
      payload:
        dataLink.POST('/entity/search', {
          select: "id,name,grayver,active,version,createTime,updateTime,nameSpell,corporationId,sourceCorporationId,dataCorporationId,ledgerIds,plannedIds,parentId,code,scoped,multiCurrencyFlag,importOverrideStrategy,visibility,type,disableStrategy,maxUsageCount,writtenOffField,fields,defaultMappingRuleId,filterId,details,homePageVisibleIds,homeVisibility,exportVisibility,ownerDepts,showOnPage,importMethod,allowRecordLog,logFields,showBook,allowAddSubType,dataFilter,platformId(id,name,active,createTime,updateTime,nameSpell,corporationId,dataCorporationId,sourceCorporationId,importMethod,adminIds,type,groupType,properties,desc,source)",
          limit: {
            start: 0,
            count: 1000
          }
        })
    }
  }

}

export function saveRules({ param, isEdit }) {
  return {
    type: key.SAVE_RULE,
    payload: isEdit ? rules.PUT('/fieldMapping/$id', param) : rules.POST('/fieldMapping', param)
  }
}

export function getMyThirdPartyList() {
  return {
    type: key.GET_MY_THIRDPARTY_LIST,
    payload: dataLink.GET('/platform/me')
  }
}

export function modifyDataLinkCount(param) {
  return {
    type: key.MODIFY_DATALINK_COUNT,
    payload: dataLinkV1.PUT('/$id/count', param)
  }
}

export function disableDataLink(param) {
  return {
    type: key.DISABLE_DATALINK,
    payload: supplierV3.PUT('/contract/$id/state', param)
  }
}

//业务对象置顶/取消置顶
export function setTopDataLink(param) {
  const { id, state } = param
  return {
    type: key.SETTOP_DATALINK,
    payload: dataLinkV1.PUT(`/$id/top?state=${state}`, { id })
  }
}

export function getDataLink(param, query = {}) {
  return {
    type: key.GET_DATALINK,
    payload: dataLinkV1.POST('/searchDataLink/$entityId', param, query)
  }
}

export function searchDatalink(param, query = {}) {
  const { type, ...others } = param
  return {
    type: key.SEARCH_DATALINK,
    payload: dataLink.POST('/searchDataLinks', { ...param, child: true, ...query })
  }
}

export function getEntityByPlatformName(name, filter) {
  const join = `platformId,platformId,/v2/datalink/platform`
  let filterBy = filter
  //@i18n-ignore
  if (name === '行程管理') {
    filterBy = `(type== ("TAXI")|| type== ("HOTEL") ||type== ("TRAIN") ||type== ("FLIGHT") ||type== ("ORDER"))`
  }
  return {
    type: key.GET_ENTITY_BY_PLATFORM_NAME,
    payload: dataLink.POST(
      '/entity/getEntityByPlatformName/$name',
      {
        filterBy: filterBy ? filterBy : ''
      },
      { name, join }
    )
  }
}

export function getImportStatus(params) {
  return dataLinkV1.GET('/excel/import/result/$entityId', params)
}

export function setLocal(id, flag) {
  let str = 'dataLink:' + id
  flag ? localStorage.setItem(str, JSON.stringify(flag)) : localStorage.removeItem(str)
}

export function getLocal(id) {
  return JSON.parse(localStorage.getItem('dataLink:' + id))
}

export function changeFZRorCYRList(param) {
  return {
    type: key.CHANGE_FZR_OR_CYR_LIST,
    payload: dataLinkV1.PUT('/$type/$id/updateCategory', param)
  }
}

//执行计划
export function saveDatalinkPlanned(param) {
  return {
    type: key.SAVE_DETALINK_PLANED,
    payload: param.id ? dataLink.PUT('/planned/$id', param) : dataLink.POST('/planned', param)
  }
}

export function datalinkPlanDelete(id) {
  return {
    type: key.DATALINK_PLAN_DELETE,
    payload: dataLink.DELETE('/planned/$id', { id })
  }
}

export function getMoneyFieldByEntityId(param) {
  return {
    type: key.GET_MONEY_FIELD_BYENTITYID,
    payload: dataLink.GET('/planned/entityId/$entityId', param)
  }
}

export function getBindEntityFieldsWithEntityId(param) {
  return {
    type: key.GET_BIND_ENTITY_FIELDS,
    payload: ledgerAction.GET('/entity/$datalinkEntityId', param)
  }
}

export function getLedgerMaxCount() {
  return {
    type: key.GET_LEDGER_MAX_Count,
    payload: dataLink.GET('/ledger/max/count')
  }
}

export function getPlannedMaxCount() {
  return {
    type: key.GET_PLAN_MAX_Count,
    payload: dataLink.GET('/planned/max/count')
  }
}

export function getFieldWithAbility(param) {
  return {
    type: key.GET_MONEY_ABILITY_FIELDS,
    payload: propertySetAction.POST('/ability', param)
  }
}

export function updateLedgerConfig(param) {
  const { id, ...others } = param
  return {
    type: key.UPDATE_LEDGER_CONGIF,
    payload: id ? dataLink.PUT('/ledger/$id', param) : dataLink.POST('/ledger', others)
  }
}

export function deleteLedgerConfig(param) {
  const { id } = param
  return {
    type: key.DELETE_LEDGER_CONGIF,
    payload: dataLink.DELETE('/ledger/$id', { id })
  }
}

export function postVisibleList(entityId, homePageVisibleIds) {
  return {
    type: key.POST_VISIBLE_LIST,
    payload: dataLink.POST('/entity/homePageVisibleIds/$entityId', {
      entityId,
      homeVisibility: { ...homePageVisibleIds, fullVisible: false }
    })
  }
}

export function getVisibleList(ids) {
  const params = { ids }
  return {
    type: key.GET_VISIBLE_LIST,
    payload: dataLinkHomeVisible.POST('/staffs/more', params)
  }
}

export function getLedgerList(entityId) {
  return {
    type: key.GET_LEDGER_LIST,
    payload: dataLink.GET('/ledger/byEntityId/$entityId', { entityId })
  }
}

export function getPlanList(entityId) {
  return {
    type: key.GET_PLAN_LIST,
    payload: dataLink.GET('/planned/byEntityId/$entityId', { entityId })
  }
}

export function getColumnVisibility(entityId) {
  return {
    type: key.GET_COLUMN_VISIBILITY,
    payload: dataLink.GET('/fieldsVisibility', { entityId })
  }
}

export function setColumnVisibility(entityId, permissions) {
  return {
    type: key.SET_COLUMN_VISIBILITY,
    payload: dataLink.POST('/fieldsVisibility', { entityId, permissions })
  }
}

export function getLedgerInfoById(id) {
  return {
    type: key.GET_LEDGER_INFO_BY_ID,
    payload: dataLink.GET('/ledger/$id', { id })
  }
}

export function reComputeLedger(params) {
  const { ledgerConfigId, specificationId, start, end, statisticsSource, entityIds } = params
  let query = new QuerySelect()
  if (statisticsSource === 'DATA_LINK') {
    const filter = `(entityId.in(${entityIds.map((id) => `"${id}"`).join(',')}))`
    query.filterBy(filter)
  } else {
    const ids = specificationId.map((id) => `"${id}"`).join(',')
    const filter = `(form.specificationId.originalId.in(${ids}) ||form.specificationId.specificationGroupId.in(${ids}))`
    query
      .filterBy(filter)
      .filterBy(`form.submitDate > ${start}`)
      .filterBy(`form.submitDate < ${end}`)
  }

  return {
    type: key.RECOMPUTE_LEDGET_CONFIG,
    payload: dataLink.POST('/ledger/reCompute/$ledgerConfigId', query.value(), { ledgerConfigId })
  }
}

export function getTempConfigCandidate(dataLinkId) {
  return {
    type: key.GET_TEMPCONFIG_CANDIDATE,
    payload: dataLinkV1.GET('/entityField/$dataLinkId', { dataLinkId })
  }
}

export function getDataLinkTemplate(dataLinkEntityId) {
  return {
    type: key.GET_DATALINK_TEMPLATE,
    payload: dataLink.GET('/template/$dataLinkEntityId', { dataLinkEntityId })
  }
}

export function saveDataLinkTemplate(param) {
  return {
    type: key.SAVE_DATALINK_TEMPLATE,
    payload: dataLink.PUT('/template/$id', param)
  }
}

export function getEntityData(params) {
  return {
    type: key.GET_ENTITY_DATA,
    payload: dataLink.GET('/searchDataLink/$dataLinkId/$type', params)
  }
}

export function getEntityDetailData(params) {
  return {
    type: key.GET_ENTITY_DETAIL_DATA,
    payload: dataLink.GET('/detail/$dataLinkId/$type', params)
  }
}

export function getSearchDataLink(params) {
  const { start, count, filterBy, entityId, type, category, orderBy } = params
  let query = new QuerySelect()
  if (count) {
    query.limit(start, count)
  }
  if (filterBy) {
    query.filterBy(filterBy)
  }
  query = query.value()
  return {
    type: key.GET_SEARCH_DATA_LINK,
    payload: dataLink.POST(
      '/searchDataLink/$entityId/$type/$category',
      { ...query, orderBy },
      { entityId, type, category }
    )
  }
}

export function searchPlatfromDatalink(params, querys = {}) {
  const { query = {}, ...others } = params
  const param = {
    query,
    ...others
  }

  return {
    type: key.SEARCH_PLATFORM_DATALINK,
    payload: dataLink.POST('/searchPlatformDataLinkWithChild/$entityId', param, querys)
  }
}
export function searchOrderMatchOrder(params, querys = {}) {
  const { query = {}, ...others } = params
  const param = {
    query,
    ...others
  }

  return {
    type: key.SEARCH_ORDER_MATCH_ORDER,
    payload: dataLink.POST('/searchOrderMatchOrder/$entityId', param, querys)
  }
}
export function handleExportAll(id, query) {
  const syncUrl = '/api/v1/datalink/excel/export/all'
  const asyncUrl = '/api/v1/datalink/excel/export/async'
  return computeExport(id, syncUrl, asyncUrl, query)
}

export function handleLayoutExport(id, query) {
  const config = query?.config ? query?.config : null
  const entityId = encodeURIComponent(id)
  const asyncUrl = '/api/v1/datalink/excel/export/async/layout'
  return api.open('@layout:AsyncExportModal').then((v) => {
    const asUrl = `${asyncUrl}/$${entityId}`
    const params = { taskName: v.taskName, isFilter: false }
    if (!!query?.filterBy) {
      params.filter = query.filterBy
    }
    if (config) {
      params.config = JSON.stringify(config)
    }
    return Fetch.GET(asUrl, params)
  })
}

export function handleSelectExport(id, query, ids) {
  const syncUrl = `/api/v1/datalink/excel/export/[${ids.join(',')}]`
  const entityId = encodeURIComponent(id)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let sUrl = `${syncUrl}/$${entityId}?corpId=${ekbCorpId}`
  if (query?.filterBy) {
    sUrl = `${sUrl}&filter=${query.filterBy}`
  }
  return api.emit('@vendor:download', sUrl)
}

export function computeExport(id, syncUrl, asyncUrl, query) {
  const config = query?.config || null
  const entityId = encodeURIComponent(id)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const InquireUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/datalink/excel/exportWay/$${entityId}`
  let sUrl = `${syncUrl}/$${entityId}?corpId=${ekbCorpId}`
  if (query?.filterBy) {
    sUrl = `${sUrl}&filter=${encodeURIComponent(query.filterBy)}`
  }

  if (config) {
    sUrl = `${sUrl}&config=${encodeURIComponent(JSON.stringify(config))}`
  }
  return Fetch.GET(InquireUrl).then((v) => {
    if (v.value.exportWay === 'async') {
      return api.open('@layout:AsyncExportModal').then((v) => {
        const asUrl = `${asyncUrl}/$${entityId}`
        const data = { taskName: v.taskName }
        if (config) {
          data.config = JSON.stringify(config)
        }
        if (query?.filterBy) {
          data.filter=query.filterBy
        }
        return Fetch.GET(asUrl, data)
      })
    } else {
      return api.emit('@vendor:download', sUrl)
    }
  })
}

// 我的业务对象导出 <<< 接口加参数
export function handleExportAllMine(id, query) {
  const syncUrl = '/api/v1/datalink/excel/export/all'
  const asyncUrl = '/api/v1/datalink/excel/export/async'
  return computeExportMine(id, syncUrl, asyncUrl, query)
}

export function handleLayoutExportMine(id, query) {
  const entityId = encodeURIComponent(id)
  const asyncUrl = '/api/v1/datalink/excel/export/async/layout'
  return api.open('@layout:AsyncExportModal').then((v) => {
    const asUrl = `${asyncUrl}/$${entityId}`
    const params = { taskName: v.taskName, isFilter: true }
    if (!!query?.filterBy) {
      params.filter = query.filterBy
    }
    params.category = query?.category
    if (query?.config) {
      params.config = JSON.stringify(query?.config)
    }

    return Fetch.GET(asUrl, params)
  })
}
export function handleExportPDF(id, query) {
  const entityId = encodeURIComponent(id)
  const asyncUrl = `/api/v2/datalink/async/$${entityId}`
  const params = {}
  if (!!query?.filterBy) {
    params.filter = query.filterBy
  }
  params.category = query?.category
  return Fetch.GET(asyncUrl, params)
}
export function computeExportMine(id, syncUrl, asyncUrl, query) {
  const config = query?.config || null
  const entityId = encodeURIComponent(id)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const InquireUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/datalink/excel/exportWay/$${entityId}`
  let sUrl = `${syncUrl}/$${entityId}?corpId=${ekbCorpId}&category=${query?.category}`
  if (query?.filterBy) {
    sUrl = `${sUrl}&filter=${encodeURIComponent(query.filterBy)}`
  }
  if (config) {
    sUrl = `${sUrl}&config=${encodeURIComponent(JSON.stringify(config))}`
  }
  return Fetch.GET(InquireUrl, { category: query?.category }).then((v) => {
    if (v.value.exportWay === 'async') {
      return api.open('@layout:AsyncExportModal').then((v) => {
        const asUrl = `${asyncUrl}/$${entityId}`
        const data = { taskName: v.taskName, category: query?.category }
        if (config) {
          data.config = JSON.stringify(config)
        }
        return Fetch.GET(asUrl, data)
      })
    } else {
      return api.emit('@vendor:download', sUrl)
    }
  })
}

export function saveSpecificationType(params) {
  return {
    type: key.SAVE_SPECIFICATIONT_TYPE,
    payload: specification.POST(`/apportion`, params)
  }
}

export function editSpecificationType(params) {
  return {
    type: key.EDIT_SPECIFICATIONT_TYPE,
    payload: specification.PUT(`/apportion/$id`, params)
  }
}
export function deleteSpecificationType(params) {
  return {
    type: key.DELETE_SPECIFICATIONT_TYPE,
    payload: specification.DELETE('/apportion/$id', params)
  }
}
export function dataLinkToElem(dataLinkId) {
  return {
    type: key.DATALINK_TO_ELEME,
    payload: dataLinkElem.PUT('/$dataLinkId', { dataLinkId })
  }
}

export function getDataLinkLogByEntityId(params) {
  return {
    type: key.GET_DATALINK_LOG_BY_ENTUTYID,
    payload: dataLink.POST('/log/byEntityId/$id', params)
  }
}

export function getDataLinkLogByDataLinkId(params) {
  return {
    type: key.GET_DATALINK_LOG_BY_DATALINKID,
    payload: dataLink.POST('/log/byDataLinkId/$id', params)
  }
}

// 纷享销客获取对象下的字段
export function getFieldsListForFXiaoKe(params) {
  return tpp.POST('/fxiaoke/entity/reload', params)
}

// 纷享销客保存对象下的字段编辑结果
export function postFieldsListForFXiaoKe(params) {
  const { async, body } = params
  return tpp.POST('/fxiaoke/setting/entity/update', body, { async })
}

export function getEntityFieldInfoById(id) {
  return {
    type: key.GET_DATALINK_LOG_BY_DATALINKID,
    payload: dataLink.GET('/entity/field/$dataLinkEntityId', { dataLinkEntityId: id })
  }
}
export function getLogs(ids) {
  return {
    type: key.GET_TRAVEL_MANAGE_LOG,
    payload: tpp.POST('/dataLink/logList', { ids: ids })
  }
}

export function getLogsByDataLinkId(id) {
  const newId = encodeURIComponent(id)
  return {
    type: key.GET_TRAVEL_MANAGE_LOG_BY_DATALINKID,
    payload: tpp.GET(`/dataLink/log/${newId}`)
  }
}

export function syncTravelData(value) {
  return {
    type: key.SYNC_TARVEL_DATA,
    payload: tpp.POST('/dataLink/resync', value)
  }
}

export function getEntityDimension(params) {
  return {
    type: key.GET_DATALINK_DIMENSION,
    payload: dataLink.GET('/entity/dimension/$id', params)
  }
}

export async function getEntityDimensionCount() {
  try {
    const result = await dataLink.GET('/entity/dimension/count')
    return Promise.resolve(result.count)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getDataLinkFields(params) {
  try {
    const result = await dataLink.GET('/entity/getFields/$id', params)
    return Promise.resolve(result.items)
  } catch (e) {
    return Promise.reject(e)
  }
}

export function getEntityDimensionField(params) {
  if (!params.field) return { items: [] }
  return ledgerAction.GET('/entity/$datalinkEntityId/dimension/$field', params)
}

// 获取要编辑业务对象的渲染模板
export function getEntityFormInfo(params) {
  return dataLink.GET('/entityEdit/entityForm/$entityId/$isInsert', params)
}

// 修改业务对象数据接口
export function entityEdit(params) {
  return dataLink.POST('/entityEdit/edit', params)
}

// 批量修改业务对象数据接口
export function entityEdits(params) {
  return dataLink.POST('/entityEdit/edits', params)
}

export function getDATALINK() {
  return propertySetAction.GET('/$DATALINK', { DATALINK: 'DATALINK' })
}

export async function checkFieldDependence(params) {
  const res = (await tpp.POST('/fxiaoke/check/entities', params)) || {}
  return Promise.resolve(res.value)
}

export async function getDimensionsByIds(ids) {
  return dimensions.GET('/[ids]', { ids })
}

export async function getDepartmentsByIds(ids) {
  return departments.GET('/[ids]', { ids })
}

export function getMCPermission(permission, nameList) {
  return !!permission.find((v) => nameList.includes(v.name) && v.auth)
}

export async function checkApportionFormula(params) {
  return specification.POST('/apportion/checkFormula', params)
}



export function saveFields(data) {
  return {
    type: key.SAVE_FIELDS,
    payload: propertySet.POST('/property', { ...data })
  }
}
/**
 * 订单监控中心获取订单列表
 * @param query
 * @returns {Promise<any>}
 */
export function getOrderReportDataList(params) {
  return orderCenter.POST('/search', params)
}




/**
 * 通过开票方Id获取订单列表：统一开票未开票处使用
 * @param params : {id: 开票方id, ...}
 * @returns {Promise<any>}
 */
export function getOrderReportUnifyInvoiceDataListByCorpId(params) {
  return tppOrder.POST('/unify/$id', params)
}

/**
 * 开票批次Id获取订单列表：统一开票的开票批次关联的订单
 * @param params : {id: 开票批次id, ...}
 * @returns {Promise<any>}
 */
export function getOrderReportUnifyInvoiceDataListByBatchId(params) {
  return tppOrder.POST('/unify/batch/$id', params)
}

/**
 * 获取订单所需的全局字段
 * @returns {Promise<any>}
 */
export function getOrderReportFields() {
  return tppOrder.GET('/field')
}

//获取自定义文档树
export function getDimensionList(id) {
  return dimension.GET('/$id', { id })
}

//批量停用
export function updateDatalinkActive(params) {
  return dataLinkV1.POST('/state', params)

}

// 获取回单字段
export function getReceiptDataLinkField() {
  return {
    type: key.GET_REVEIPT_DATALINK_FIELD,
    payload: dataLink.GET('/entity/getFields/$type', { type: 'system_电子回单' })
  }
}
